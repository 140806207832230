.carousel {
  position: relative;
  z-index: 5;
  img {
    height: 100vh;
  }
}
.gioiThieu {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;

  position: absolute;
  left: 0;
  top: 65px;
  padding: 40px;
  padding-left: 170px;
  color: #ffffff;
  background: rgba(0, 0, 0, 0.682);
  text-align: left;
  width: 562px;
  height: 100%;
  text-align: justify;
  z-index: 6;

  h5 {
    margin: 30px 0 30px 0;
  }
}
@media (max-width: 1199px) {
  .gioiThieu {
    top: 110px;
    width: 100%;
    height: 101vh;
    padding-left: 40px;
  }
  .carousel {
    position: relative;
    height: 100vh;
    overflow: hidden;
    img {
      object-fit: fill;
      width: 100%;
      height: 100vh;
    }
  }
}
.BaiViet{
  margin-top: 50px;

  .Thembaiviet{
    height: 150px;
    width: 150px;
    border: 1px solid black;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: xx-large;
    cursor: pointer;
    transition: font-size 0.1s ease-in-out;
    &:hover {
        font-size: 50px;
    }
  }
  .item{

    .image{
        max-width: 100%;
        height: 150px;
    }
  }
}
.scrollIcon{
  font-size: 25px;
  color: white;
  position: fixed;
  bottom: 20px;
  left: 200px;
  z-index: 100;
  svg{
    transition: transform 0.09s ease-in-out;
  }
  &:hover{
    svg{
      transform: scale(1.2);
    }
  }
  p{
    font-size: 15px;
  }
}