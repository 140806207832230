.Title {
  height: 270px;
  background-image: url("../../image/About/AboutUs.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  h2 {
    color: rgb(255, 255, 255);
    font-size: 40px;
  }
}
.content {
  text-align: left;
  margin-bottom: 50px;
  p {
    font-size: 14px;
    line-height: 2;
  }
}

.tieude {
  margin-top: 50px;
  margin-bottom: 40px;
  font-size: 20px;
}
