.BaiViet{
    margin-top: 50px;
    text-align: left;
    h1{
        font-size: 25px;
    }
    span{
        font-size: 15px;
    }
    .content{
        margin-top: 20px;
    }
    img{
        max-width: 100%;
        max-height: 500px;
    }
}
.NDBaiViet{
    margin-top: 50px;
    text-align: left;
    h1{
        font-size: 17px;
    }
    span{
        font-size: 15px;
    }
    .content{
        margin-top: 20px;
    }
    img{
        margin-top: 30px;
        width: 100%;
        max-height: 1000px;
    }
}

.BaiVietRight{
    margin-top: 50px;
    text-align: left;
    border-left: 1px solid black;
    padding-left: 40px;
    h1{
        font-size: 25px;
    }
    span{
        font-size: 15px;
    }
    .content{
        margin-top: 20px;
    }
    img{
        max-width: 100%;
        max-height: 500px;
    }
    .item{
        padding-top: 10px;
        .image{
            height: 200px;
        }
    }
}