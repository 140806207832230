.list_congtrinh {
  padding: 40px;
  margin-left: 70px;
}
.ND {
  height: 34px;
  width: 100%;
  text-align: center;
  margin-top: 200px;
  // border-top: 1px solid black;
}
a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
  outline: none;
}
.congtrinh {
  cursor: pointer;
  margin-left: 70px;
  position: relative;
  text-align: center;
  // border: 1px solid black;
  margin-bottom: 20px;
  padding: 0;
  border-radius: 5px;
  img {
    border-radius: 5px;
    width: auto;
    max-height: 200px;
    max-width: 366px;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    transition: top 0.8s ease-in-out;
  }
}

@media (max-width: 1200px) {
  .congtrinh {
    margin-left: 0px;
    margin: auto;
    margin-top: 20px;
    .overlay {
      margin: 0;
      p {
        left: auto;
      }
    }
    &:hover .overlay {
      height: 81%;
      opacity: 1;
    }
    .ND {
      margin-top: 200px;
    }
    img {
      border-radius: 5px;
    }
  }
  .list_congtrinh {
    margin-left: 0px;
    overflow-x: hidden;
    padding: 0px;
    padding-left: 30px;
    width: 100%;
    text-align: center;
  }
}
