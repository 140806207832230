.container {
  overflow: hidden !important;

  user-select: none;
  background-color: rgba(0, 0, 0, 0.742);
}
.detail {
  border-radius: 10px;
  padding: 30px;
  background-color: rgba(0, 0, 0, 0.633);
  position: fixed;
  top: 65px;
  left: -420px;
  max-height: 100vh;
  width: 700px;
  z-index: 99;
  color: aliceblue;
  transition: left 0.5s;
}
p {
  font-size: small;
}
.detail_list {
  text-align: left;
  font-size: 14px;
  li {
    margin-bottom: 10px;
  }
}
.detail:hover {
  left: 0px;
}

.popup {
  padding: 40px;
  text-align: right;
  margin-top: 300px;
}

.arrow {
  cursor: pointer;
  font-size: 30px;
  color: aliceblue;
}
@keyframes slide-in {
  0% {
    left: 0px;
  }
  100% {
    left: -420px;
  }
}
.detail_list {
  order: 1;
  li {
    list-style-type: none;
  }
}
.detail_info {
  order: 2;
}

@media (max-width: 1200px) {
  .detail {
    padding: 30px;
    background-color: rgba(0, 0, 0, 0.633);
    position: sticky;

    height: 100%;
    width: 100%;
    z-index: 10;
    color: aliceblue;
    transition: left 0.5s;
  }
  p {
    font-size: small;
  }
  .detail_list {
    text-align: left;
    font-size: 14px;
    li {
      margin-bottom: 10px;
    }
  }
  svg {
    display: none;
  }
  .popup {
    padding: 40px;
    text-align: right;
    margin-top: 300px;
  }

  .arrow {
    display: none;
  }
  .detail_list {
    order: 2;
  }
  .detail_info {
    margin-bottom: 20px;
    order: 1;
  }
}
.imageDetail {
  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
  }
}
.image-gallery-content .image-gallery-slide .image-gallery-image {
  max-height: 100%;
}
