.header {
  margin: 0 30px;
  font-family: Arial, sans-serif;
  p {
    margin-top: 30px;
    text-transform: uppercase;
    font-size: small;
    cursor: pointer;
  }
}
.spaced {
  display: none;
}
.logo {
  text-align: center;
  img {
    padding: 0 5px;
    margin-top: 6px;
    height: 58px;
    border-radius: 8px;
    transition:
      background-color 0.5s ease,
      border-radius 0.5s ease;
    &:hover {
      background-color: #ff914c;
    }
  }
}
.icon {
  img {
    margin-top: 20px;
    height: 35px;
    padding: 5px;
    margin-left: 30px;
    cursor: pointer;
    border-radius: 8px;
    transition:
      background-color 0.5s ease,
      border-radius 0.5s ease;
    &:hover {
      background-color: #ff914c;
    }
  }
}
@media (max-width: 1199px) {
  .spaced {
    display: block;
  }
  .logo {
    text-align: right;
  }
  .header {
    margin: 0 0px;
  }
  .Projects {
    p {
      width: 110%;
    }
  }
  .icon {
    padding-right: 30px;
    text-align: left;
    img {
      margin-left: 10px;
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
  .space {
    display: none;
  }
}
.option {
  height: 65px;
  a {
    text-decoration: none;
  }
  p {
    color: black;
    padding: 5px;
    border-radius: 6px;
    transition:
      background-color 0.5s ease,
      border-radius 0.5s ease;
  }
  p:hover {
    background-color: #ff914c;
  }
}
.icon_mb {
  img {
    margin-top: 23px;
    height: 24px;
    cursor: pointer;
  }
}
