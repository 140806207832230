.Title {
  height: 270px;
  background-image: url("../../image/OurTeam.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  h2 {
    color: rgb(255, 255, 255);
    font-size: 40px;
  }
}
.List_people {
  padding: 40px;
}
.people {
  margin-left: 19px;
  margin-bottom: 50px;
  padding: 0;
  height: 400px;
  width: 225px;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.23);
  img {
    width: 100%;
    height: 325px;
  }
}
.info {
  .left {
    text-align: center;
    .logo {
      height: 150px;
    }
  }
  .center {
    text-align: left;
    padding-left: 70px;
  }
  .right {
    text-align: left;
  }
  hr {
    width: 50px;
    color: black;
    border: 2px rgb(0, 0, 0) solid;
  }
}
.ND {
  padding-top: 10px;
}
.people {
  position: relative;

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    transition:
      height 0.3s ease-in-out,
      opacity 0.3s ease-in-out;

    img {
      width: 100%;
      height: auto;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      transition: top 0.8s ease-in-out;
    }
  }

  &:hover .overlay {
    height: 81%;
    opacity: 1;

    img {
      top: 50%;
    }
  }
}
.overlay {
  text-align: justify;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: aliceblue;
}
.tiktok {
  max-width: 580px;
  min-width: 288px;
  max-height: 158px;
  border: 1px solid rgba(0, 0, 0, 0.144);
  border-radius: 9px;
}
@media (max-width: 1200px) {
  .people {
    margin: auto;
    margin-bottom: 30px;
  }
}
